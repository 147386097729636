import "./BookingBox.css";

export default function BookingBox(){
    let url = "https://Psychic.as.me/USA";

    if(navigator.language == "en-GB"){
        url = "https://Psychic.as.me/";
    }

    return(
        <div className="booking-box flex column">    
            <a
              href= {url}
              target="_blank"
              className="acuity-embed-button"
            >
              Schedule Appointment
            </a>
      </div>
    )
}