import { useState } from "react";
import "./Carosel.css";
import { useEffect } from "react";

let testimonials = [
  "“It felt like I’d known Penelope forever rather than talking to a stranger. She has such a calming personality and a lovely person.”  - E.S.",
  "“Penelope is amazing. She gave me several messages from my grandparents about steps I should take in my life and things to be careful of. My favourite part of the reading would definitely be hearing from my grandparents as they said they are proud of me!” - T.G.",
  "“My reading was accurate. Penelope was able to tell me things about my life that I’ve never told anyone. This let me know that she is the real deal!” - S.W.",
  "“The messages were always very personal and so true. Every reading helped me to understand things that had happened or were happening and some questions answered that I didn't expect to ever be answered” - N.M.",
  "“Penelope knew things no one could ever have known” - D.R.",
  "“My reading was amazing absolutely spot on thank you very much can’t wait until I have another reading in the future” - A.S.",
  "“ I got told to stop wearing silly shoes (sliders) as I was going to have a bad fall. 4 weeks ago I fell due to my sliders and dislocated my elbow” - C.G.",
  "“I connected with my mother and I truly believe she was present. Also, the personal things that were shared about me were pretty accurate as well. It impacts me to this very day! It confirmed that I'm not alone and that I'm doing the right thing for my kids and I.” - R.S",
  "“My favorite part of my reading was connecting with my mother and knowing that she is with me and considers my accomplishments, her accomplishments. That means the world to me. Honestly, after my reading, I feel more connected to my mom and more at peace with life in general. It was a life changing experience for me. Another great thing was confirmation that I'm not tied to where I live, that I will probably move in the future which I am happy about!” - N.H",
  "“The reading was uncannily accurate and allowed me to move into this new chapter of my life with confidence and a clearer mind.” - B.K",
  "“Penelope was very kind, genuine, and funny. I truly enjoyed the reading and will certainly book again upon the next major life change on the horizon.” - G.R.",
  "“I appreciated how calming and friendly Penelope is. She takes her time and stays with you as long as needed.” - J.D.",
  "“Very accurate brought me to tears” - L.B",
  "“Penelope’s energy was amazing. She was spot on and she made me feel so comfortable. I highly recommend her.” - R.M.",
  "“Insane!!! Friendly safe space and an amazing experience. Absolutely spot on cant wait to go again. Amazing x” - H.S.",
  "“I was a believer before but it was really the part where she communicated with my loved ones that resonated for me. It gave me closure, perspective and guidance. This was an amazing experience.” - J.F.",
  "“Penelope was very welcoming & friendly, she is value for money in fact mine ran over time which was not a problem... she was thorough Highly recommend” - M.H.",
  "“These readings help me enormously. I always leave feeling refreshed, and empowered. My readings from this service are always accurate.” - R.D.",
  "“Meeting my grandma for the first time and hearing everything she said and knowing she was always there and one of the ones I always felt was comforting in ways I can't explain. This reading meant so much to me. It was truly eye opening and worth every penny. I'm very grateful for the experience.” - B.W.",
  "“It was a mixed bag of soul freeing messages, confirmation, insight and very few unclear messages.” - K.R.",
  "“The message regarding my (unborn) children being with my grandmother was the most profound part of the reading. I have yet to realize the business opportunity mentioned. I still haven’t left my boyfriend. It was stated that we’d both be happier with someone else. Thanks for the messages. I will be returning as a client and have referred several people” - H.D.",
];

export default function Carosel() {
  let width = window.innerWidth;
  let maxIndex;
  let increment;
  if (width > 1104) {
    maxIndex = 2;
    increment = 3;
  } else if (width > 695 && width < 1104) {
    maxIndex = 1;
    increment = 2;
  } else {
    maxIndex = 0;
    increment = 1;
  }
  let maxIndexDefault = maxIndex;

  let starterArray;

  switch (maxIndex) {
    case 2:
      starterArray = [
        testimonials[maxIndex - 2],
        testimonials[maxIndex - 1],
        testimonials[maxIndex],
      ];
      break;
    case 1:
      starterArray = [testimonials[maxIndex - 1], testimonials[maxIndex]];
      break;
    case 0:
      starterArray = [testimonials[maxIndex]];
      break;
  }

  const [currentTestimonials, setCurrentTestimonials] = useState(starterArray);

  useEffect(() => {
    const interval = setInterval(() => {
      if (maxIndex > 19) {
        maxIndex = maxIndexDefault;
      } else {
        maxIndex += increment;
      }

      switch (maxIndexDefault) {
        case 2:
          setCurrentTestimonials([
            testimonials[maxIndex - 2],
            testimonials[maxIndex - 1],
            testimonials[maxIndex],
          ]);
          break;
        case 1:
          setCurrentTestimonials([
            testimonials[maxIndex - 1],
            testimonials[maxIndex],
          ]);
          break;
        case 0:
          setCurrentTestimonials([testimonials[maxIndex]]);
          break;
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carosel-container flex">
      {currentTestimonials.map((testimonial) => (
        <div
          className="testimonial flex"
          style={{ animationName: "fadeInOut" }}
        >
          <p>{testimonial}</p>
        </div>
      ))}
    </div>
  );
}
