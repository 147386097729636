import AccordianMenu from "./AccordianMenu";
import BookingBox from "./BookingBox";
import Carosel from "./Carosel";
import "./Home.css";

export default function Home() {
  return (
    <div className="main flex column">
      <div className="quote-container top-quote">
        <p>If you have found me, it was because you were sent.</p>
        <p>Sent by your Guides</p>
        <p>...sent by your friends</p>
        <p>...or, sent by your own curiosity.</p>
      </div>
      <BookingBox/>
      <AccordianMenu />
      <div className="transparent-box">
        <div className="text-container">
          <div className="about-box flex column">
            <h1 className="about-title title">About</h1>
            <p>
              Thank you for enquiring about A Reading with Penelope. No doubt
              you have been referred by a friend or colleague who uses my
              services so they may have already given you some information about
              me and how I work.
            </p>
            <p>
              I am a psychic medium who has a background in hypnotherapy. I 
              offer the “complete experience” using both Spirit messages from 
              your ancestors, and tarot cards. I am an intuitive reader and call 
              on my three Guides for assistance and support. I am based in the UK 
              and fully insured to conduct my international services online via 
              video call. I have over 10 years experience, and numerous clients 
              from all over the world as well as my client base in the UK.
            </p>
            <p>
              My readings usually follow a therapeutic or healing theme, but I
              do realise that some clients just want to connect with their
              deceased loved ones or ancestors whilst getting a further insight
              into their day to day lives. They may also want questions answered
              and this can be done via spirit, cards, or both.
            </p>
            <p>
              I love first time readings because this is where I connect with
              your deceased loved ones and Guides. I am a medium first and
              foremost and this is my first love. Channelling messages from them
              to you, is rather like being the telephone not the operator.
            </p>
            <p>
              I want to make you feel as comfortable as possible regarding your
              journey with me and reassure you that there will be no cost to you
              until you are. Please feel free to take your time until you are
              ready. All these other details such as cost, time zones and
              various terms and conditions are accessible through the link
              provided. There is no rush. When you are ready, please go straight 
              through to the Schedule a Reading on the Menu portal and click the 
              button that applies to your time zone.
            </p>
            <p>
              Sometimes I never see you again and this can be bittersweet. But
              there’s an overall happiness because if this is the case, my
              purpose has been fulfilled. Second and third readings are always
              more enlightening and are very rewarding because it is then that
              we can work together on and with your own spirituality and or
              spiritual connections, should that be your wish, to obtain
              whatever support and assistance you require on your journey. If 
              you want to discover more about the methods I use and my own 
              spiritual practise then please clink on the link in the menu and 
              follow the blog.
            </p>
            <p>
              For my existing clients, I know this information about me is not
              new, but for those of you who’ve never accessed my services
              before, below is some helpful first- time information you may need
              should you wish to proceed further.
            </p>
          </div>
          <div className="your-reading-box flex column">
            <h1 className="your-reading-title title">Your Reading</h1>
            <ul>
              <li>
                I am not a fortune-teller. If you want that kind of reader,
                please do not book with me. The readings I offer are an insight
                to your life that will assist and support you with any decisions
                you need to make. I will not tell you what to do.
              </li>
              <li>
                Please keep in mind that this is for entertainment purposes
                only.
              </li>
              <li>
                Please be open-minded. Do not block me or your Spirit Guides and
                Ancestors - it will only waste your time and money. Your
                appointment is not a test of my ability. In my experience,
                Spirit do not care, for example, if you take off your wedding
                ring and expect me to tell you whether you are in a relationship
                or not. They know you already! The information they give me is
                relevant to YOU not me.
              </li>
              <li>
                I reserve the right to stop a reading if I feel it is necessary.
                Although this is extremely rare, I will usually know within a
                few minutes of meeting you that this may be a possibility. I
                will not waste Spirit time or indeed yours or mine and will
                return your payment in the method you made.
              </li>
              <li>
                You are often given the message that Spirit deems you require
                and sometimes it may not be one you will expect. However, they
                are ALWAYS sent with love.
              </li>
              <li>
                Further to that please be aware that you can’t come into a
                reading and demand a loved one speaks to you because you have
                paid them money. This is highly disrespectful and always ends in
                disappointment. I work for Spirit. They do not work for me and
                unfortunately, I do not return payment if you are bored or
                unhappy with the Spirit or haven’t read these conditions of
                booking.
              </li>
            </ul>
          </div>
          <div className="commitments-box flex column">
            <h1 className="commitments-title title">My Commitments</h1>
            <p>
              I will be honest, respectful, and open with you during your
              reading. Some topics may be sensitive or triggering. You are fully
              within your right to ask that certain subjects are not to be
              spoken about and we will move on. Please feel open to tell me if
              you are feeling uncomfortable, as this is not how I wish to
              conduct my readings.
            </p>
          </div>
          <div className="aftercare-box flex column">
            <h1 className="terms-title title">Aftercare</h1>
            <p>
              I appreciate that some readings may be deeply emotional. I have
              sat in your chair myself and understand that, after a reading, you
              may experience a variety of feelings. If I notice that you may be
              finding things difficult, I always make it my responsibility to
              check on you in the following days. I believe aftercare is
              important and will never leave you feeling like you are on your
              own.
            </p>
            <p>
              I will not cross a professional boundary in doing so and you are
              perfectly within your right to decline this. It is not a charged
              “extra”. It is something that I believe is morally right and the
              correct thing to do.
            </p>
          </div>
          <div className="final-note-box flex column">
            <h1 className="terms-title title">A Final Note</h1>
            <ul>
              <li>
                The cards you choose are the cards of the day, and the decisions
                that you may have to make are relative to how you feel on that
                day. They can NOT dictate or change your future. Only you can do
                that.
              </li>
              <li>
                If you are wanting messages from Spirit, start asking them to
                connect with you at the time of booking; think about them, talk
                to them, questions you want to ask them and memories of them.
              </li>
              <li>
                If you'd like questions answered, start thinking about them or
                write them down.
              </li>
              <li>
                In doing this, I find that your questions are usually answered
                without you having to ask me directly but you are welcome to ask
                me further questions in your reading.
              </li>
            </ul>
            <p>
              The purpose of your reading is to empower you and I will do my
              best on the day.
            </p>
            <p>I look forward to seeing you!</p>
          </div>
        </div>
      </div>
      <div className="transparent-box">
        <div className="text-container">
          <div className="hypnotherapy-and-meditiations-box flex column">
            <h1 className="hypnotherapy-and-meditiations-title title">Hypnotherapy and Meditations </h1>
            <p>
              Penelope is a fully qualified hypnotherapist who holds a Diploma in 
              Hypnotherapy Therapy award. This confirms that Penelope has demonstrated 
              professional knowledge of Hypnosis, Suggestion Therapy and Analytical 
              Therapy by successfully completing an examination on these subjects.
            </p>
            <p>
              Penelope is a a suitable person to practise hypnotherapy and is 
              therefore entitled to use the letters: DHP Acc.Hyp. 
            </p>
            <p>
              Penelope has also satisfied the criteria for competency, experience 
              and integrity in the discrete discipline of Hypnotherapy and is 
              accordingly appointed a full member of the Professional Hypnotherapy 
              Practitioner Association (PHPA).
            </p>
            <p>
              Penelope is fully insured to practice hypnotherapy and write, record, 
              practise and produce meditations. 
            </p>
            <p>
              Penelope’s meditations have a number of different themes from basic 
              relaxation to linking with your Spirit Guides and more. She is passionate 
              about encouraging her clients to develop their own spiritual practices 
              if this is what they wish to do. 
            </p>
            <p>
              Please click on the appropriate links to enjoy access to free guided meditations.
            </p>
          </div>
        </div>
      </div>
      <div className="quote-container bottom-quote">
        <p>
          “There is no failure, just a series of unfinished tasks.” (Malcolm,
          2022)
        </p>
      </div>
      <h1 className="testimonials-title title decorated">
        <span>Testimonials</span>
      </h1>
      <Carosel />
      <div className="booking-box flex column">
        <a
          href="https://app.acuityscheduling.com/schedule.php?owner=18726587&appointmentType=45590411"
          target="_blank"
          className="acuity-embed-button"
        >
          Schedule Appointment
        </a>
        <script
          src="https://embed.acuityscheduling.com/embed/button/18726587.js"
          async
        ></script>
      </div>
    </div>
  );
}
