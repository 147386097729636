import React, {useState} from "react"
import "./AccordianMenu.css";

export default function AccordianMenu(){
    const [isActive, setIsActive] = useState(false);

    return(
        <div className="accordion">
            <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
              <h1>Links{isActive ? '-' : '+'}</h1>
            </div>
            {isActive && 
            <div className="accordion-content">
                <div className="accordion-link">
                    <h3><a href="https://areadingwithpenelope.wordpress.com/">Blog</a></h3>
                </div>
                <div className="accordion-link">
                    <h3><a href="https://www.youtube.com/channel/UCIWZ5iCLy_IqJBtUJpgQRCA">Meditations</a></h3>
                </div>
                <div className="accordion-link">
                    <h3><a href="https://www.instagram.com/areadingwithpenelopeofficial?igsh=MXh6NTN0ajN4NDJiMQ==">Instagram</a></h3>
                </div>
            </div>}
        </div>
    )
}