import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container flex">
        <h3><a href="mailto:knightwebdesign@proton.me">By Knight Web Design</a></h3>
        <h3>2024</h3>
      </div>
    </footer>
  );
}
